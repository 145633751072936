import styled, { css } from 'styled-components'

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;

  @media (max-width: 1280px) {
    width: calc(100% - 160px);
  }

  @media (max-width: 768px) {
    width: calc(100% - 40px);
  }

  ${props =>
    props.wide &&
    css`
      @media (max-width: 768px) {
        width: calc(100%);
      }
    `}
`

export default Container
