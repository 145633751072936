import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import OutsideClickHandler from 'react-outside-click-handler'
import styled, { withTheme } from 'styled-components'

import LOGO_CONTACT_SMARTER from '../images/cs-logo-color.svg'
import LOGO_CONTACT_SMARTER_WHITE from '../images/cs-logo.svg'
import ISO_LOGO from '../images/logo-blue.svg'
import ISO_LOGO_WHITE from '../images/logo-white.svg'

import Hamburger from '../images/hamburger.inline.svg'
import Cross from '../images/cross.inline.svg'

const Container = styled.div`
  margin: 0 auto;
  min-height: 80px;
  position: relative;
  z-index: 4;

  .sticky {
    position: fixed;
    right: 0;
    left: 0;
    transition: background-color 100ms ease;

    .header-content {
      display: flex;
      align-items: center;
      max-width: 1280px;
      margin: 0 auto;
      padding: 20px 0;
    }

    &.fixed {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 4;
    }

    &.white {
      background-color: ${props => props.theme.colors.white};
      box-shadow: 0 1px 0 0 rgba(122, 131, 143, 0.15);
    }

    &.blue {
      background-color: #0765e2;
    }
  }

  @media (max-width: 1280px) {
    width: calc(100vw - 160px);

    .sticky {
      .header-content {
        padding: 20px;
      }
    }
  }

  @media (max-width: 544px) {
    width: 100vw;
    min-height: 44px;

    .sticky {
      .header-content {
        padding: 8px 20px;
      }
    }
  }
`

const Logo = styled.img`
  width: 100%;
  height: 24px;
  user-select: none;
  object-fit: contain;
`

const Navbar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: auto;
  height: 24px;

  .mobile-action {
    cursor: pointer;
    display: none;
  }

  svg {
    width: 20px;
    max-height: 20px;
  }

  path,
  polygon,
  g {
    fill: ${props =>
      !props.isBlack || props.heroScroll
        ? props.theme.colors.white
        : props.theme.colors.gray};
  }

  @media (max-width: 840px) {
    .mobile-action {
      display: flex;
    }
  }
`

const GetStartedLink = styled.a`
  background-color: ${props => props.theme.colors.green};
  color: ${props => props.theme.colors.white};
  font-weight: 600;
  font-size: ${props => props.theme.unit.getRem(14)};
  border-radius: 5px;
  padding: 12px 24px;
  transition: background-color 250ms ease-in;
  text-transform: uppercase;

  :hover {
    background-color: ${props => props.theme.colors.hover.green};
  }

  :focus {
    background-color: ${props => props.theme.colors.focus.green};
  }
`

const GetStartedLinkMobile = styled.a`
  background-color: ${props => props.theme.colors.primary};
  border-radius: 5px;
  color: ${props => props.theme.colors.white};
  min-width: 80px;
  padding: 8px 24px;
  font-weight: 600;
  font-size: ${props => props.theme.unit.getRem(12)};
  margin-right: 16px;
  transition: background-color 250ms ease-in;
  text-transform: uppercase;
  user-select: none;

  :hover {
    background-color: ${props => props.theme.colors.hover.primary};
  }

  :focus {
    background-color: ${props => props.theme.colors.focus.primary};
  }
`

const Menu = styled.ul`
  font-weight: 500;
  font-size: ${props => props.theme.unit.getRem(16)};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  list-style-type: none;
  margin: 0;
  user-select: none;

  li {
    margin-left: 32px;

    a {
      text-decoration: none;
      color: ${props =>
        !props.isBlack || props.heroScroll
          ? props.theme.colors.white
          : props.theme.colors.black};
    }

    a.active {
      color: ${props => props.theme.colors.primary};
    }
  }

  ${GetStartedLink} {
    background-color: ${props =>
      !props.isBlack || props.heroScroll
        ? props.theme.colors.green
        : props.theme.colors.primary};
    color: ${props => props.theme.colors.white};
  }

  @media (max-width: 840px) {
    background-color: ${props => props.theme.colors.white};
    border: solid 1px rgba(122, 131, 143, 0.2);
    border-radius: 5px;
    line-height: 1.5;
    display: ${props => (props.display === 'SHOW' ? 'flex' : 'none')};
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: absolute;
    min-height: 96px;
    min-width: 116px;
    padding: 16px;
    top: 24px;
    right: 0;
    bottom: 0;

    li {
      margin-left: 0;
      margin-bottom: 8px;

      a {
        color: ${props => props.theme.colors.black};
      }
    }
  }
`

const StyledNavbar = ({ black }) => {
  const [display, setDisplay] = useState(false)
  const [isScrolling, setIsScrolling] = useState(false)
  const [isHamburger, setIsHamburger] = useState(false)
  const [scrollingBeforeHero, setScrollingBeforeHero] = useState(false)

  const onResize = () => {
    const { width: clientWidth } = document
      .getElementById('___gatsby')
      .getBoundingClientRect()

    return setIsHamburger(clientWidth > 840 ? false : true)
  }

  function fixNav() {
    const nav = document.querySelector('.sticky')

    if (window.scrollY > nav.offsetTop) {
      const header = document.getElementById('hero-container')

      if (!header) {
        if (window.scrollY >= 80) {
          nav.classList.add('white')
        } else {
          nav.classList.remove('white')
        }
        return null
      }

      const { height } = header.getBoundingClientRect()
      nav.classList.add('fixed')

      if (window.scrollY > height) {
        setScrollingBeforeHero(false)
        nav.classList.remove('blue')
        nav.classList.add('white')
      } else {
        setScrollingBeforeHero(true)
        nav.classList.remove('white')
        if (window.scrollY >= 80) {
          nav.classList.add('blue')
        } else {
          nav.classList.remove('blue')
        }
      }

      setIsScrolling(true)
    } else {
      nav.classList.remove('fixed')
      nav.classList.remove('blue')
      nav.classList.remove('white')
      setIsScrolling(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', fixNav)
    window.addEventListener('resize', onResize)
    onResize()

    return () => {
      window.removeEventListener('scroll', fixNav)
    }
  }, [])

  const getLogo = () => {
    if (isHamburger && scrollingBeforeHero && isScrolling) return ISO_LOGO_WHITE

    if (isScrolling && isHamburger) return ISO_LOGO

    if ((black || isScrolling) && !scrollingBeforeHero)
      return LOGO_CONTACT_SMARTER

    return LOGO_CONTACT_SMARTER_WHITE
  }

  return (
    <OutsideClickHandler onOutsideClick={() => setDisplay(false)}>
      <Container className="container" id="header">
        <div className="sticky">
          <div className="header-content">
            <Link to="/">
              <Logo src={getLogo()} />
            </Link>

            <Navbar
              isBlack={black || isScrolling}
              heroScroll={scrollingBeforeHero}
            >
              {!scrollingBeforeHero && isScrolling && isHamburger && (
                <GetStartedLinkMobile
                  href="https://app.contactsmarter.com/signup"
                  style={{ textDecoration: 'none' }}
                >
                  GET STARTED
                </GetStartedLinkMobile>
              )}

              {display ? (
                <Cross
                  onClick={() => setDisplay(false)}
                  className="mobile-action"
                />
              ) : (
                <Hamburger
                  onClick={() => setDisplay(true)}
                  className="mobile-action"
                />
              )}

              <Menu
                isBlack={black || isScrolling}
                heroScroll={scrollingBeforeHero}
                display={display ? 'SHOW' : 'HIDE'}
              >
                <li>
                  <Link
                    activeClassName="active"
                    partiallyActive={true}
                    to="/features"
                  >
                    Features
                  </Link>
                </li>
                <li>
                  <Link
                    activeClassName="active"
                    partiallyActive={true}
                    to="/pricing"
                  >
                    Pricing
                  </Link>
                </li>
                <li>
                  <a href="https://app.contactsmarter.com/login">Log in</a>
                </li>
                {!isHamburger && (
                  <li>
                    <GetStartedLink
                      href="https://app.contactsmarter.com/signup"
                      style={{ textDecoration: 'none' }}
                    >
                      Get started for free
                    </GetStartedLink>
                  </li>
                )}
              </Menu>
            </Navbar>
          </div>
        </div>
      </Container>
    </OutsideClickHandler>
  )
}

export default withTheme(StyledNavbar)
